<template style="background-color: #dedede">
  <div >
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>设备数量统计</span>
      </div>
      <div>
        <div id="myChart" style="width: 100%;height:600px"></div>
      </div>
    </el-card>

    <el-card class="box-card2">
      <div slot="header" class="clearfix">
        <span>设备分类统计</span>
      </div>
      <div>
        <div id="shebeiFbtj" style="width: 100%;height:600px"></div>
      </div>
    </el-card>

    <el-card class="box-card3">
      <div slot="header" class="clearfix">
        <span>设备使用率</span>
      </div>
      <div>
        <div id="shebeiFbt" style="width: 100%;height:600px"></div>
      </div>
    </el-card>
  </div>
  </template>

<script>

  export default {
		name: "Index",
    data() {
      return {

      }
    },
    created() {
    },
    mounted() {
      this.drawLine();
      this.shebeiFbtj();
      this.shebeiFbt();
    },
    methods:{
      drawLine(){
        // 基于准备好的dom，初始化echarts实例
        let myChart = this.$echarts.init(document.getElementById('myChart'))
        // 绘制图表
        myChart.setOption({
          backgroundColor: "#05224d",
          tooltip: {},
          grid: {
            top: "8%",
            left: "1%",
            right: "1%",
            bottom: "8%",
            containLabel: true,
          },
          legend: {
            itemGap: 50,
            data: ["设备总量", "当月设备增量"],
            textStyle: {
              color: "#f9f9f9",
              borderColor: "#fff",
            },
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: true,
              axisLine: {
                //坐标轴轴线相关设置。数学上的x轴
                show: true,
                lineStyle: {
                  color: "#f9f9f9",
                },
              },
              axisLabel: {
                //坐标轴刻度标签的相关设置
                textStyle: {
                  color: "#d1e6eb",
                  margin: 15,
                },
              },
              axisTick: {
                show: false,
              },
              data: ["1月", "2月", "3月", "4月", "5月", "6月", "7月"],
            },
          ],
          yAxis: [
            {
              type: "value",
              min: 0,
              // max: 140,
              splitNumber: 7,
              splitLine: {
                show: true,
                lineStyle: {
                  color: "#0a3256",
                },
              },
              axisLine: {
                show: false,
              },
              axisLabel: {
                margin: 20,
                textStyle: {
                  color: "#d1e6eb",
                },
              },
              axisTick: {
                show: false,
              },
            },
          ],
          series: [
            {
              name: "设备总量",
              type: "line",
              // smooth: true, //是否平滑曲线显示
              // 			symbol:'circle',  // 默认是空心圆（中间是白色的），改成实心圆
              showAllSymbol: true,
              symbol: "emptyCircle",
              symbolSize: 6,
              lineStyle: {
                normal: {
                  color: "#28ffb3", // 线条颜色
                },
                borderColor: "#f0f",
              },
              label: {
                show: true,
                position: "top",
                textStyle: {
                  color: "#fff",
                },
              },
              itemStyle: {
                normal: {
                  color: "#28ffb3",
                },
              },
              tooltip: {
                show: false,
              },
              areaStyle: {
                //区域填充样式
                normal: {
                  //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                  color: this.$echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          offset: 0,
                          color: "rgba(0,154,120,1)",
                        },
                        {
                          offset: 1,
                          color: "rgba(0,0,0, 0)",
                        },
                      ],
                      false
                  ),
                  shadowColor: "rgba(72,200,248,0.9)", //阴影颜色
                  shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
                },
              },
              data: [393, 438, 485, 631, 689, 824, 987],
            },
            {
              name: "当月设备增量",
              type: "bar",
              barWidth: 20,
              tooltip: {
                show: false,
              },
              label: {
                show: true,
                position: "top",
                textStyle: {
                  color: "#fff",
                },
              },
              itemStyle: {
                normal: {
                  color: function (params) {
                    var colorList = [
                      "#0ec1ff",
                      "#10cdff",
                      "#12daff",
                      "#15ebff",
                      "#17f8ff",
                      "#1cfffb",
                      "#1dfff1",
                    ];
                    return colorList[params.dataIndex];
                  },
                },
              },
              data: [200, 382, 102, 267, 186, 315, 316],
            },
          ],
        });
      },
      shebeiFbtj(){
        let myChart = this.$echarts.init(document.getElementById('shebeiFbtj'))
        var scale = 1;
        var echartData = [
          {
            value: 2149,
            name: "切石机",
          },
          {
            value: 3823,
            name: "碎石机",
          },
          {
            value: 3515,
            name: "锯石机",
          },

        ];
        var rich = {
          yellow: {
            color: "#ffc72b",
            fontSize: 30 * scale,
            padding: [5, 4],
            align: "center",
          },
          total: {
            color: "#ffc72b",
            fontSize: 40 * scale,
            align: "center",
          },
          white: {
            color: "#fff",
            align: "center",
            fontSize: 14 * scale,
            padding: [21, 0],
          },
          blue: {
            color: "#49dff0",
            fontSize: 16 * scale,
            align: "center",
          },
          hr: {
            borderColor: "#0b5263",
            width: "100%",
            borderWidth: 1,
            height: 0,
          },
        };
        let option;
        option = {
          backgroundColor: "#031f2d",
          title: {
            text: "总设备数",
            left: "center",
            top: "53%",
            padding: [24, 0],
            textStyle: {
              color: "#fff",
              fontSize: 18 * scale,
              align: "center",
            },
          },
          legend: {
            selectedMode: false,
            formatter: function (name) {
              var total = 0; //各科正确率总和
              var averagePercent; //综合正确率
              echartData.forEach(function (value, index, array) {
                total += value.value;
              });
              return "{total|" + total + "}";
            },
            data: [echartData[0].name],
            // data: ['高等教育学'],
            // itemGap: 50,
            left: "center",
            top: "center",
            icon: "none",
            align: "center",
            textStyle: {
              color: "#fff",
              fontSize: 16 * scale,
              rich: rich,
            },
          },
          series: [
            {
              name: "总设备数量",
              type: "pie",
              radius: ["42%", "50%"],
              hoverAnimation: false,
              color: ["#c487ee", "#deb140", "#49dff0", "#034079", "#6f81da", "#00ffb4"],
              label: {
                normal: {
                  formatter: function (params, ticket, callback) {
                    var total = 0; //考生总数量
                    var percent = 0; //考生占比
                    echartData.forEach(function (value, index, array) {
                      total += value.value;
                    });
                    percent = ((params.value / total) * 100).toFixed(1);
                    return (
                        "{white|" +
                        params.name +
                        "}\n{hr|}\n{yellow|" +
                        params.value +
                        "}\n{blue|" +
                        percent +
                        "%}"
                    );
                  },
                  rich: rich,
                },
              },
              labelLine: {
                normal: {
                  length: 55 * scale,
                  length2: 0,
                  lineStyle: {
                    color: "#0b5263",
                  },
                },
              },
              data: echartData,
            },
          ],
        }
        // 绘制图表
        myChart.setOption(option)
      },
      shebeiFbt(){
        let myChart = this.$echarts.init(document.getElementById('shebeiFbt'));
        var dataArr = 44;
        var colorSet = {
          color: "#468EFD",
        };
        let option;
        option = {
          backgroundColor: "#0E1327",
          tooltip: {
            formatter: "{a} <br/>{b} : {c}%",
          },

          series: [
            {
              name: "内部进度条",
              type: "gauge",
              // center: ['20%', '50%'],
              radius: "40%",

              splitNumber: 10,
              axisLine: {
                lineStyle: {
                  color: [
                    [dataArr / 100, colorSet.color],
                    [1, "#111F42"],
                  ],
                  width: 8,
                },
              },
              axisLabel: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              itemStyle: {
                show: false,
              },
              detail: {
                formatter: function (value) {
                  if (value !== 0) {
                    var num = Math.round(value);
                    return parseInt(num).toFixed(0) + "%";
                  } else {
                    return 0;
                  }
                },
                offsetCenter: [0, 82],
                textStyle: {
                  padding: [0, 0, 0, 0],
                  fontSize: 18,
                  fontWeight: "700",
                  color: colorSet.color,
                },
              },
              title: {
                //标题
                show: true,
                offsetCenter: [0, 46], // x, y，单位px
                textStyle: {
                  color: "#fff",
                  fontSize: 14, //表盘上的标题文字大小
                  fontWeight: 400,
                  fontFamily: "PingFangSC",
                },
              },
              data: [
                {
                  name: "title",
                  value: dataArr,
                },
              ],
              pointer: {
                show: true,
                length: "75%",
                radius: "20%",
                width: 10, //指针粗细
              },
              animationDuration: 4000,
            },
            {
              name: "外部刻度",
              type: "gauge",
              //  center: ['20%', '50%'],
              radius: "50%",
              min: 0, //最小刻度
              max: 100, //最大刻度
              splitNumber: 10, //刻度数量
              startAngle: 225,
              endAngle: -45,
              axisLine: {
                show: true,
                lineStyle: {
                  width: 1,
                  color: [[1, "rgba(0,0,0,0)"]],
                },
              }, //仪表盘轴线
              axisLabel: {
                show: true,
                color: "#4d5bd1",
                distance: 25,
                formatter: function (v) {
                  switch (v + "") {
                    case "0":
                      return "0";
                    case "10":
                      return "10";
                    case "20":
                      return "20";
                    case "30":
                      return "30";
                    case "40":
                      return "40";
                    case "50":
                      return "50";
                    case "60":
                      return "60";
                    case "70":
                      return "70";
                    case "80":
                      return "80";
                    case "90":
                      return "90";
                    case "100":
                      return "100";
                  }
                },
              }, //刻度标签。
              axisTick: {
                show: true,
                splitNumber: 7,
                lineStyle: {
                  color: colorSet.color, //用颜色渐变函数不起作用
                  width: 1,
                },
                length: -8,
              }, //刻度样式
              splitLine: {
                show: true,
                length: -20,
                lineStyle: {
                  color: colorSet.color, //用颜色渐变函数不起作用
                },
              }, //分隔线样式
              detail: {
                show: false,
              },
              pointer: {
                show: false,
              },
            },
          ],
        };
        // 绘制图表
        myChart.setOption(option)
      }
    }
  }
</script>

<style>

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}

.box-card {
  width: 100%;
  float: left;
  margin-bottom: 20px;
}
.box-card2 {
  width: 49%;
  float: left;
}
.box-card3 {
  width: 49%;
  float: right;
}

</style>