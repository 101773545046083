import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"

import axios from './axios'
import global from './globalFun'

import BaiduMap from 'vue-baidu-map'


import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts

Vue.prototype.$axios = axios
Vue.config.productionTip = false

// require("./mock.js")



Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.use(BaiduMap, {
  ak: 'AeaZnC09yXfcgrgCAlBltkDqBai7VQre'
})